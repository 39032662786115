import { FC } from "react";
import TimePicker, { TimePickerProps } from "react-time-picker";
import Cross from "../assets/icons/cross.svg?react";

import "react-time-picker/dist/TimePicker.css";
import "../styles/components/customInput.css";

export const CustomTimePicker: FC<
  TimePickerProps & {
    label?: string;
    wrapperClassname?: string;
    autoWidth?: boolean;
    hint?: string;
  }
> = ({ hint, label, id, required, wrapperClassname, ...rest }) => {
  return (
    <div className={`custom-input-wrapper ${wrapperClassname || ""}`}>
      {label && (
        <label htmlFor={id} className="custom-input-label">
          {label} {required && <span className="text-red-600">*</span>}
        </label>
      )}
      <TimePicker clearIcon={Cross} className="custom-time-picker" {...rest} />
      {hint && <div className="custom-input-hint">{hint}</div>}
    </div>
  );
};
